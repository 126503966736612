<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        class="d-flex align-center justify-space-between"
      >
        <span class="titulo-pagina">
          {{ tituloFormulario }}
        </span>
      </v-col>
    </v-row>
    <v-form
      ref="form"
      class="row"
    >
      <input-text
        v-if="ehFinalizar"
        class="col-12 col-md-6"
        :label="
          $t('modulos.roteiro_execucao_envio_material.formulario.veiculo')
        "
        disabled
        :value="veiculo"
      />
      <InputVeiculos
        v-else
        v-model="form.veiculo"
        :multiplos="false"
        class="col-12 col-md-6"
        apenas-disponivel
        :label="
          $t('modulos.roteiro_execucao_envio_material.formulario.veiculo')
        "
        obrigatorio
      />
      <input-text
        v-if="ehFinalizar"
        v-model="form.kmChegada"
        class="col-12 col-md-6"
        type="number"
        :min="0"
        :label="
          $t('modulos.roteiro_execucao_envio_material.formulario.kmChegada')
        "
        :placeholder="
          $t('modulos.roteiro_execucao_envio_material.formulario.kmChegada')
        "
        :regras-personalizadas="regraKmChegadaMaiorQueKmSaida()"
        obrigatorio
      />
      <input-text
        v-else
        v-model="form.kmSaida"
        class="col-12 col-md-6"
        type="number"
        :label="
          $t('modulos.roteiro_execucao_envio_material.formulario.kmSaida')
        "
        :min="0"
        :regras-personalizadas="regraKmSaida()"
        obrigatorio
      />
      <input-textarea
        v-model="form.observacao"
        class="col-12"
        :label="
          $t('modulos.roteiro_execucao_envio_material.formulario.observacao')
        "
        :obrigatorio="false"
      />
      <accordion-padrao
        :alterar-cor="true"
        cor-azul-texto
        :titulo="
          $t(
            'modulos.roteiro_execucao_envio_material.formulario.checklistVeiculo'
          )
        "
        class="accordion-padrao col-12 mb-5"
      >
        <span>{{
          this.$t(
            'modulos.roteiro_execucao_envio_material.formulario.checklistMensagem'
          )
        }}</span>
        <tabela-padrao
          v-model="tabela.selecionados"
          :dados="tabela.dados"
          class="mt-2"
          :colunas="tabela.colunas"
          :por-pagina="tabela.porPagina"
          :quantidade-paginas="tabela.quantidadePaginas"
          sem-paginacao
          sem-acoes
          :mobile-breakpoint="1"
        />
      </accordion-padrao>
    </v-form>
    <v-divider class="my-5" />

    <template>
      <div class="d-flex align-center justify-center justify-md-end">
        <botao-padrao
          outlined
          color="secondary"
          class="mr-2 flex flex-grow-1 flex-md-grow-0"
          btn100
          @click="cancelar"
        >
          <v-icon class="mr-1">
            $mdiCancel
          </v-icon>
          {{ $t('geral.botoes.cancelar') }}
        </botao-padrao>
        <template>
          <botao-padrao
            v-if="ehFinalizar"
            color="secondary"
            outlined
            class="flex-grow-1 flex-md-grow-0 mr-2"
            btn100
            @click="visualizarRoteiro"
          >
            <v-icon class="mr-1">
              $mdiContentSaveOutline
            </v-icon>
            {{ $t('geral.botoes.visualizar_roteiro') }}
          </botao-padrao>
          <botao-padrao
            v-if="ehFinalizar"
            class="flex-grow-1 flex-md-grow-0"
            btn100
            :tooltip="tooltip"
            :disabled="podeIniciar"
            @click="finalizar"
          >
            <v-icon class="mr-1">
              $mdiContentSaveOutline
            </v-icon>
            {{ $t('geral.botoes.salvar') }}
          </botao-padrao>
          <botao-padrao
            v-else
            class="flex-grow-1 flex-md-grow-0"
            btn100
            :tooltip="tooltip"
            :disabled="podeIniciar"
            @click="iniciar"
          >
            <v-icon>$playCircleOutline</v-icon>
            {{ $t('modulos.roteiro_execucao_envio_material.botoes.iniciar') }}
          </botao-padrao>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { InputVeiculos } from '@/components/inputs';
import { RoteiroExecutarEnvioMaterialModel } from '@/common/models/cadastros/RoteiroExecutarEnvioMaterialModel';
import {
  RoteiroEnvioMaterialService,
  VeiculoService,
} from '@/common/services/cadastros';

export default {
  components: {
    InputVeiculos,
  },
  data() {
    return {
      veiculo: '',
      roteiro: {},
      roteiroId: null,
      form: new RoteiroExecutarEnvioMaterialModel({}),
      veiculoWatcher: null,
      podeExecutarWatcher: true,
      valido: false,
      tabela: {
        selecionados: [],
        dados: [
          {
            id: 1,
            flag: 'flagNivelOleo',
            nome: this.$t(
              'modulos.roteiro_execucao_envio_material.formulario.tabela.nivelOleo'
            ),
          },
          {
            id: 2,
            flag: 'flagAguaRadiador',
            nome: this.$t(
              'modulos.roteiro_execucao_envio_material.formulario.tabela.aguaRadiador'
            ),
          },
          {
            id: 3,
            flag: 'flagAguaParabrisa',
            nome: this.$t(
              'modulos.roteiro_execucao_envio_material.formulario.tabela.aguaParabrisa'
            ),
          },
          {
            id: 4,
            flag: 'flagFreiosPneus',
            nome: this.$t(
              'modulos.roteiro_execucao_envio_material.formulario.tabela.freiosPneus'
            ),
          },
          {
            id: 5,
            flag: 'flagFaroisLuzes',
            nome: this.$t(
              'modulos.roteiro_execucao_envio_material.formulario.tabela.faroisLuzes'
            ),
          },
        ],
        colunas: [
          {
            value: 'nome',
            text: this.$t(
              'modulos.roteiro_execucao_envio_material.formulario.tabela.selecionarTodos'
            ),
            sortable: false,
          },
        ],
      },
    };
  },
  computed: {
    tituloFormulario() {
      const { name } = this.$route;

      if (name === 'roteiro-executar-envio-material-finalizar')
        return this.$t(
          'modulos.roteiro_execucao_envio_material.titulos.finalizar',
          { data: new Date().toLocaleDateString() }
        );

      return this.$t(
        'modulos.roteiro_execucao_envio_material.titulos.iniciarRoteiro',
        { data: new Date().toLocaleDateString() }
      );
    },
    podeIniciar() {
      if (!this.valido) return true;
      if (
        this.tabela.selecionados.length < 5 &&
        this.form.observacao?.length > 0
      )
        return false;
      if (this.tabela.selecionados.length < 5) return true;

      return false;
    },
    ehFinalizar() {
      const { name } = this.$route;
      return name === 'roteiro-executar-envio-material-finalizar';
    },
    tooltip() {
      if (!this.valido) {
        return this.$t(
          'modulos.roteiro_execucao_envio_material.mensagem.preenchaTodosCampos'
        );
      }
      if (
        this.tabela.selecionados.length < 5 &&
        this.form.observacao.length < 1
      ) {
        return this.$t(
          'modulos.roteiro_execucao_envio_material.mensagem.selecioneTodos'
        );
      }
      return '';
    },
  },
  watch: {
    form: {
      handler: function () {
        this.valido = this.$refs.form.validate();
      },
      deep: true,
    },
    ehFinalizar: async function () {
      await this.inicializar();
    },
  },
  async mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.roteiro_execucao_envio_material.titulos.exerucaoRoteiro')
    );

    await this.inicializar();
  },
  methods: {
    cancelar: function () {
      if (window.innerWidth < 600) {
        this.$router.push({
          name: 'dashboard',
        });
        return;
      }
      this.$router.push({
        name: 'roteiro-envio-material',
      });
    },
    iniciar: function () {
      const valido = this.$refs.form.validate();
      if (!valido) return;
      this.form.flags = this.tabela.selecionados;

      this.$store.dispatch('Layout/iniciarCarregamento');
      RoteiroEnvioMaterialService.iniciarRoteiro(
        this.roteiro.roteiroId,
        this.form.request
      )
        .then(() => {
          this.$router.push({
            name: 'roteiro-executar-envio-material-checkin',
          });
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    finalizar: function () {
      const valido = this.$refs.form.validate();
      if (!valido) return;
      this.form.flags = this.tabela.selecionados;
      this.$store.dispatch('Layout/iniciarCarregamento');
      RoteiroEnvioMaterialService.finalizarRoteiro(
        this.roteiro.roteiroId,
        this.form.request
      )
        .then(() => {
          this.$store.dispatch('Roteiro/limparRoteiro');
          this.$router.push({
            name: 'roteiro-executar-envio-material-finalizado',
          });
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    regraKmChegadaMaiorQueKmSaida: function () {
      if (!this.form.kmChegada) return [];

      const regraValor = (v) => {
        if (parseInt(v) < parseInt(this.form.kmSaida))
          return this.$t(
            'modulos.roteiro_execucao_envio_material.mensagem.kmchegadaNaoPodeSerMenor',
            { kmSaida: this.form.kmSaida }
          );

        return true;
      };
      let regraNaoPodeDecimal = (v) => {
        if (v % 1 != 0) {
          return this.$t(
            'modulos.roteiro_execucao_envio_material.mensagem.naoPodeDecimal'
          );
        }
        return true;
      };

      return [regraValor, regraNaoPodeDecimal];
    },
    regraKmSaida: function () {
      if (!this.form.kmSaida) return [];

      let regraNaoPodeDecimal = (v) => {
        if (v % 1 != 0) {
          return this.$t(
            'modulos.roteiro_execucao_envio_material.mensagem.naoPodeDecimal'
          );
        }
        return true;
      };

      return [regraNaoPodeDecimal];
    },

    inicializar: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.podeExecutarWatcher = false;
      await RoteiroEnvioMaterialService.buscarRoteiroDia()
        .then((res) => {
          this.roteiro = res.data;
          const { name } = this.$route;

          if (name == 'roteiro-executar-envio-material-finalizar') {
            this.form = new RoteiroExecutarEnvioMaterialModel(
              res.data.veiculoHistorico
            );
            this.veiculo = res.data.veiculoHistorico.veiculo.nome;
            return;
          }

          this.$store.dispatch('Roteiro/atualizarRoteiro', this.roteiro);
          const todosClienteConcluidos = this.roteiro.clientes.every(
            (cliente) => {
              return cliente.situacao == 'Concluido';
            }
          );

          if (todosClienteConcluidos && this.roteiro.situacao == 'EmExecucao') {
            this.$router.push({
              name: 'roteiro-executar-envio-material-finalizar',
              params: {
                roteiroId: this.roteiro.roteiroId,
              },
            });
            return;
          }

          if (this.roteiro.situacao == 'EmExecucao') {
            this.$router.push({
              name: 'roteiro-executar-envio-material-checkin',
            });
            return;
          }

          if (this.roteiro.situacao == 'Concluido') {
            this.$router.push({
              name: 'roteiro-executar-envio-material-finalizado',
            });
          }
        })
        .catch(() => {
          this.$router.push({
            name: 'roteiro-executar-envio-material-sem-roteiro',
          });
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });

      this.veiculoWatcher = this.$watch('form.veiculo', (veiculo) => {
        if (this.ehFinalizar) return;

        if (!veiculo) return;
        this.$store.dispatch('Layout/iniciarCarregamento');
        VeiculoService.buscarHistorico(veiculo.value)
          .then((res) => {
            if (res.data.kmChegada) {
              this.form.kmSaida = res.data.kmChegada;
            } else {
              this.toastAlerta(
                this.$t(
                  'modulos.roteiro_execucao_envio_material.mensagem.naoTemKmChegada'
                )
              );
            }
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
            this.valido = this.$refs.form.validate();
          });
      });

      this.podeExecutarWatcher = true;
    },
    visualizarRoteiro() {
      this.$router.push({
        name: 'roteiro-executar-envio-material-visualizar',
      });
    }
  },
};
</script>
